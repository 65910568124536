import Meeting from './Meeting';
import Dashboard from './Dashboard';
import Privacy from './Privacy';
import Page from './Page'
import CompanyPage from './CompanyPage'

const Layouts = {
  Meeting,
  Dashboard,
  Privacy,
  Page,
  CompanyPage
};

export default Layouts;
