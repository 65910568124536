import { Box, Button, Container, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import image from '../assets/chat.jpg'
import image1 from '../assets/chat1.jpg'
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import buttonpng from "src/assets/button.png"
import call from "src/assets/calllogo.png"
import arrow from "src/assets/Arrow.png"

export default function ChatSection() {
  const theme = useTheme();
  const { t } = useTranslation();



  return (
    <>
    <Box>
      <Grid container style={{ background:'#360C6B' , display: 'flex' , alignItems: 'center', justifyContent:'center',  paddingTop: '80px' , paddingBottom: '90px' }}>
      <Grid  xs={12} sm={5} md={4} >
  
        <Box style={{ border: 0 }}>
            <Typography variant="h2"  style={{
              display: 'block',
              marginBottom: '15px',
              fontWeight: '600',
              color: '#3CE261',

            }}>
              {t('freeRandomVideoChat')}
            </Typography>
            <Typography style={{
              border: 0, color: 'white', fontSize: '1rem', 
              
            }}>
              Vid Omegle free video chat platforms allow individuals to connect with people from diverse backgrounds and cultures in real-time. 
              These platforms offer spontaneous interactions and conversations with new acquaintances. Users can engage in casual chats, make new friends, 
              or even find potential romantic connections.
            </Typography>


            <Button style={{
              background: `url(${buttonpng}) 100% 100% `, padding: '29px' , marginTop : '10px',
              backgroundRepeat:'no-repeat',
              width: '152px'
            }}>
              <Typography variant='body1' style={{ color: 'white', fontWeight: 'bold' }}>
                Start
              </Typography>
              <img src={arrow} style={{
                width: '20px',
                marginLeft: '4px',
              }} />
            </Button>
          </Box>
      </Grid>
     

      <Grid xs={12} sm={5} md={4}  style={{ display: 'flex' , justifyContent: 'end' ,[theme.breakpoints.up('sm')]: { justifyContent: 'start' }}}>
          <img
            style={{ width: '80%',
              borderRadius: '40px',
              minHeight:'300px',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              }}}
            src={image}
            alt="Profile"
          />
      </Grid>
      </Grid>

      <Grid container style={{ background:'#360C6B' , display: 'flex' , alignItems: 'center', justifyContent:'center',   paddingTop: '40px' , paddingBottom: '90px' }}>
      <Grid  xs={12} sm={5} md={4} style={{ display: 'flex' , justifyContent: 'start' }}>
          <img
           style={{ width: '80%',
            borderRadius: '40px',
            minHeight:'300px',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            }}}
            src={image1}
            alt="Profile"
          />
      </Grid>
      <Grid xs={12} sm={5}>
        <Box style={{  border: 0 }}>
            <Typography variant="h2"  style={{
              display: 'block',
              marginBottom: '15px',
              fontWeight: '600',
              color: '#3CE261',

            }}>
             {t('How to Video Call With Strangers')}
            </Typography>
            <Typography style={{ border: 0, color: 'white', fontSize: '1rem' ,textAlign: 'left',
            }}>
              To start using Vid Omegle, enable your webcam and click on "Start." You'll immediately be matched with a random person for a video chat. 
              If you want to talk to someone else, simply click "Next" to connect with a new person. You can also choose to chat with people from a specific country by selecting that option at the top of the chat app. Additionally, 
              if you prefer to chat with only girls, you can click on the "Girls" button or use the gender filter.
            </Typography>


            <Button style={{
              background: `url(${buttonpng}) 100% 100% `, padding: '29px' , marginTop : '25px',
              backgroundRepeat:'no-repeat',
              width: '152px'
            }}>
              <Typography variant='body1' style={{ color: 'white', fontWeight: 'bold' }}>
                Start
              </Typography>
              <img src={arrow} style={{
                width: '20px',
                marginLeft: '4px',
              }} />
            </Button>
          </Box>
      </Grid>
      </Grid>
      </Box>
    
     
    </>


  );
}
