import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import UserInterface from './UserInterface';
import Utils from '../../utils';
import logo from '../../assets/logo.png'
import MeetingBar from './MeetingDrawer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowY: 'hidden',
    position:'relative',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position:'relative',
    flex: 1,
  },
  row: {
    flex: 0,
    position:'relative',
  },
  absolutePosition: {
    position: 'absolute',
    bottom:'0%',
    right:'0%'
  }
}));

function Peer({ peer, items, rootHeight ,index}) {
 const meetingDrawerOpen = useSelector((state) => state.drawer.open);



  const classes = useStyles();
  // const itemHeight = (rootHeight / items) * (peer.span || 1);
  const itemHeight = meetingDrawerOpen && index == 1  ?  '200px' : (rootHeight / items) * (peer.span || 1) ;

  return (
    <Box xs={8}
      className={classes.row}
      sx={{ maxHeight: itemHeight, height: itemHeight, minHeight: itemHeight,
         }}
      
    >
      <UserInterface height={itemHeight} peer={peer} index={index} />
      {/* <img src={logo} alt='img' /> */}
    </Box>
  );
}

Peer.propTypes = {
  peer: PropTypes.object,
  items: PropTypes.number,
  rootHeight: PropTypes.number,
};

// function Column({ column, rootHeight }) {
//   const classes = useStyles();
//   const meetingDrawerOpen = useSelector((state) => state.drawer.open);
//   return (
//     <Box className={classes.column} key={column}>
//       {column.map((peer) => {
//         return <>
//           <Peer peer={peer} items={column.length} rootHeight={rootHeight} key={uuidv4()} />
//         </>
//       })}


//     </Box>
//   );
// }
function Column({ column, rootHeight ,index }) {
  const classes = useStyles();
  const meetingDrawerOpen = useSelector((state) => state.drawer.open);

  return (
    <Box className={`${classes.column} ${meetingDrawerOpen && column.length > 0 && index == 1 ? classes.absolutePosition : ''}`} key={column}>
      {column.map((peer) => (
        <Peer peer={peer} items={column.length} index={index} rootHeight={rootHeight} key={uuidv4()} />
      ))}
    </Box>
  );
}

Column.propTypes = {
  column: PropTypes.array,
  rootHeight: PropTypes.number,
};

function UIMatrix() {
  const theme = useTheme();
  const classes = useStyles();
  const interfaces = useSelector((state) => state.media.interfaces);
  const hidden = useSelector((state) => state.media.settings.hidden);
  const meetingDrawerOpen = useSelector((state) => state.drawer.open);

  const more = useSelector((state) => state.media.settings.more);
  const { height, width } = Utils.useWindowDimensions();

  const rootHeight = height - (width < 600 ? 56 : 64);

  const filteredInterfaces = interfaces.filter((e) => !hidden.set.has(e.id));

  const shownInterfaces = filteredInterfaces.length <= more.matrix
    ? filteredInterfaces
    : [
      ...[...filteredInterfaces].slice(0, more.matrix - 1),
      {
        id: uuidv4(),
        more: true,
        peers: filteredInterfaces.length - more.matrix + 1,
      },
    ];

  if (filteredInterfaces.length === 0) {
    return (
      <Box height={rootHeight} display="flex" justifyContent="center" alignItems="center">
        <Typography
          variant="h2"
          component="p"
          sx={{ color: theme.palette.text.primary, textAlign: 'center' }}
        >
          All peers hidden (
          {interfaces.length}
          )
        </Typography>
      </Box>
    );
  }

  let radix;
  if (window.innerWidth > window.innerHeight) {
    radix = Math.floor(Math.sqrt(shownInterfaces.length || 1));
  } else {
    radix = Math.ceil(Math.sqrt(shownInterfaces.length || 1));
  }
  const columns = Math.ceil(shownInterfaces.length / radix);
  const remainder = shownInterfaces.length % radix;

  const matrix = [];

  for (let i = 0; i < columns; i++) {
    matrix[i] = [];
    for (let j = 0; j < radix; j++) {
      if (i === columns - 1 && j === radix - remainder - 1) {
        matrix[i][j] = { ...shownInterfaces[i * radix + j], span: remainder + 1 };
      } else if (i === columns - 1 && j > radix - remainder - 1) {
        // do nothing
      } else {
        matrix[i][j] = shownInterfaces[i * radix + j];
      }
    }
  }

  return (
    <>
      <Grid className={classes.root} sx={{ height: rootHeight }}>
        {matrix.map((column ,i) => {
          // console.log(' column', column ,'i',i)
          return <Column column={column}  index={i} rootHeight={rootHeight} key={uuidv4()} />;
        })}
      </Grid>


      {/* <Grid className={classes.root} sx={{ height: rootHeight }}>
        {!meetingDrawerOpen ? (
          matrix.map((column) => {
            return <Column column={column} rootHeight={rootHeight} key={uuidv4()} />;
          })
        ) : (
          matrix.slice(0, 1).map((column) => { // Corrected the typo here
            return <Column column={column} rootHeight={rootHeight} key={uuidv4()} />;
          })
        )}
      </Grid> */}
    </>
  );
}

export default UIMatrix;
