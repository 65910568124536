// Import necessary React and Material-UI components
import { useTheme } from '@mui/material/styles';
import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'src/component/Footer';
import Navbar from 'src/component/Navbar';
import Common from 'src/common';
import config from 'src/config';


const TermsandCondition = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
   <Common.Page title={`Terms And Condition | ${config.appTitle}`} canonicalUrl={"/terms-condition"} description={"You can only use our website and services if you're eighteen (18) years old or older and not prohibited by law. You can't use our products if you're under 18, have been convicted of certain crimes, lack legal capacity, or are prohibited by law from using them. You also can't use our products if you're in a country under a U.S. Government embargo or designated as a 'terrorist supporting' country, or if you're on any U.S. Government list of prohibited or restricted parties. Unauthorized use of our products violates these Terms. You represent and warrant that you can enter into and comply with these Terms"}>
<>
      <Navbar />
      <Box style={{ minHeight: '80vh', background: theme.palette.background.default }}>
        <Container>

          <Paper sx={{ background: 'none', boxShadow: 'none ' }}>
            {/* Title */}
            <Typography variant="h1" style={{fontSize: '50px', fontWeight: '700'}}  padding="40px 0 0 0">
              {/* {t('privacyTitle')} */}
              Terms and Conditions of Vidomegle
            </Typography>

            <Box style={{
              font:
                'Jost',
              fontWeight:
                "400",
              fontSize:
                '17px',
              lineHeight:
                '34.68px',
                padding:'20px 0',
                color:'white'
            }}>
              While using the Vidomegle website, you must follow the Community Guidelines. You cannot use the website for illegal activities or to harm the website or its users. You also cannot deceive other users, which means you cannot use fake profiles or pretend to be someone else. Even though it's against the rules, other users might try to deceive you with fake profiles or by pretending to be someone else. You are responsible for the content you share, and other users are responsible for their content too. We don't endorse any content. Make sure to read our Privacy Policy and understand its terms.
Before using our website (vidomegle.com) and any services provided by the website, read these Terms and Conditions of Use ("Terms") carefully to understand what they mean. If you have any questions or need technical support, email officialvidomegle@gmail.com.
You can only use our products if you agree to these Terms and all applicable laws, rules, and regulations. You must be at least eighteen (18) years old and legally able to enter into a contract with us to use our products. Our products are provided by Vidomegle LLC, located at 3907 mesa dr apartment 102, Oceanside, CA 92056. By using our products, you agree to these Terms of Service ("Terms") and our privacy policy. If you don't agree with these Terms or our privacy policy, stop using our products.

Our products are always evolving. This means they may change over time, at our discretion. We may stop providing certain features or products, set limits on their use, or remove content without liability to you. Some products or features may require payment, and by using them, you agree to any additional terms

              <Typography variant="h4"  padding="20px 0 0 0" color='white'>
              {/* {t('privacyTitle')} */}
              Eligibility
            </Typography>
            You can only use our website and services if you're eighteen (18) years old or older and not prohibited by law. You can't use our products if you're under 18, have been convicted of certain crimes, lack legal capacity, or are prohibited by law from using them. You also can't use our products if you're in a country under a U.S. Government embargo or designated as a "terrorist supporting" country, or if you're on any U.S. Government list of prohibited or restricted parties. Unauthorized use of our products violates these Terms.
You represent and warrant that you can enter into and comply with these Terms.

              
              <Typography variant='h4' padding='20px 0 0 0' color='white'>
              Description of the Website and Services
              </Typography>
              Our goal is to help people connect with others around the world through real-time conversations. Our products, including websites and services, let you connect and communicate effectively with people and friends. We use data to personalize your experience and help you connect with others through live video, chat, text, and more. We're always working to improve our products and may use technologies like artificial intelligence and machine learning to enhance safety and remove illegal content.
              
              
              <Typography variant='h4' padding='20px 0 0 0' color='white'>
              Safety
              </Typography>
              Safety is a top priority for us. We use technical systems and dedicated teams to detect and address misuse of our products. We share data with affiliates and third parties to address improper content or conduct.

              
              <Typography variant='h4' padding='20px 0 0 0' color='white'>
              Global Access
              </Typography>
              To provide our products globally, we may store and distribute data worldwide, including outside your country. This includes using third-party support and affiliates

              <Typography variant='h4' padding='20px 0 0 0' color='white'>
              New Versions of the Website
              </Typography> 
              We may add new features or modify our products to improve performance, functionality, or security. You may need to agree to updated Terms to use new features or versions.

              <Typography variant='h4' padding='20px 0 0 0' color='white'>
              Emergency Services
              </Typography> 
              Our products are not intended to replace traditional emergency services like 911. You must purchase separate services for emergency access. We're not responsible for replacing your primary telephone service or providing access to emergency services.

              <Typography variant='h4' padding='20px 0 0 0' color='white'>
              Intellectual Property
              </Typography>  
              Our products are protected by copyright laws and other intellectual property laws. You're granted a limited, non-exclusive license to use our website for personal use only. By submitting content, you grant us a license to use and distribute it as described in these Terms.
                These are the Terms and Conditions of Vidomegle. By using our products, you agree to these Terms.
            </Box>
            
          </Paper> 
        </Container>

      </Box>
      <Footer />
      </>

       </Common.Page>



  );
};

// Export PrivacyPolicyPage component
export default TermsandCondition;
