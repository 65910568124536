import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'src/component/Footer';
import Navbar from 'src/component/Navbar';
import image from 'src/assets/chat.jpg'
import image1 from 'src/assets/chat1.jpg'
import logo from 'src/assets/logo3.png'
import { useTheme } from '@mui/material/styles';
import buttonpng from "src/assets/button.png"
import call from "src/assets/calllogo.png"
import vid from "src/assets/vid.png"
import play from "src/assets/Play.svg"
import png from "src/assets/image1.png"
import Common from 'src/common';
import config from 'src/config';

const About = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
  <Common.Page title={`About | ${config.appTitle}`} canonicalUrl={"/about"}  description={"Vidomegle was born from a simple yet powerful vision: the idea that individuals should have the ability to connect with new people worldwide instantly, all through the convenience of their webcam. This dream was fueled by a desire to break down barriers and foster genuine connections across geographical boundaries and cultural divides. With this mission in mind,"}>

      <Navbar />
      <Box style={{ background: theme.palette.background.default, padding: '20px 0' }}>
        <Box>
          <Box style={{ textAlign: 'center', color: theme.palette.primary.main, padding: '10px 0', fontSize: '24px', fontWeight: 'bold'  }}>
            who we are
          </Box>
        </Box>
        <Box>
          <Typography variant='h1' style={{ textAlign: 'center', color: theme.palette.secondary.main, fontSize: '60px', fontWeight: 'bold'  }}>
            About Us
          </Typography>
        </Box>

      </Box>
      <Grid container style={{ display: 'flex', alignItems: 'center', background: '#360C6B', justifyContent: 'center', padding: '30px 0' }}>
        <Grid item xs={12} md={5} style={{ marginTop: '20px' }}>
          <Box style={{ padding: '20px 10px', border: 0 }}>
            {/* <Typography variant="h3" component="h3" style={{
              display: 'block',
              padding: '10px 0',
              fontWeight: '600',
              color: '#3CE261',

            }}>
              {t('freeRandomVideoChat')}
            </Typography> */}
            <Typography style={{
              height: '200px', border: 0, color: 'white',
              lineHeight: '22px', fontFamily: 'jost', fontWeight: '400', fontSize: '17px' 
            }}>
              {t('vidomegleText')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
          <img
            style={{ height: '310px', borderRadius: '20px',  [theme.breakpoints.down('sm')]: { width: '100%' } }}
            src={image}
            alt="Profile"
          />
        </Grid>
      </Grid>
      <Grid container style={{ display: 'flex', alignItems: 'center', background: '#360C6B', justifyContent: 'space-between', padding: '30px 0' }}>
        <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
          <img
            style={{ height: '360px', borderRadius: '20px', position: 'relative', left: '150px', [theme.breakpoints.down('sm')]: { width: '100%' } }}
            src={png}
            alt="Profile"
          />
        </Grid>
        <Grid item xs={12} md={7} style={{ maginTop: '20px', background: '#2d0560', padding: '80px' }}>
          <Box style={{ padding: '20px 10px', border: 0 }}>
            <Typography variant="h2" component="h2" style={{
              display: 'block',
              padding: '10px 0',
              fontSize: '60px',
              color: '#3CE261',
              fontFamily: 'Jost',

              fontWeight: '700'

            }}>
              Our Company
            </Typography>
            <Typography style={{
              padding: '10px 0', border: 0, color: 'white',
              lineHeight: '22px',
              fontFamily: 'jost',
              fontSize: '17px',
              fontWeight: '400'
            }}>
             Vidomegle was born from a simple yet powerful vision: the idea that individuals should have the ability to connect with new people worldwide instantly, all through the convenience of their webcam. This dream was fueled by a desire to break down barriers and foster genuine connections across geographical boundaries and cultural divides. With this mission in mind,
            </Typography>
            <Typography style={{
              padding: '10px 0', border: 0, color: 'white',
              lineHeight: '22px',
              fontFamily: 'jost',
              fontSize: '17px',
        
              fontWeight: '400'
            }}>
           Vidomegle embarked on its journey to create a platform where users could engage in spontaneous and meaningful interactions with strangers from every corner of the globe. Through dedication to innovation and a commitment to inclusivity, Vidomegle has evolved into a trusted platform for online socializing, providing a space where friendships are forged, experiences are shared, and the world becomes a little smaller with each connection made.            </Typography>
          </Box>
        </Grid>


      </Grid>

      <Grid container>
        <Grid xs={12} style={{ background: '#3CE261', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '20px 0' }}>
          <Box style={{ fontFamily: 'jost', fontWeight: '700', fontSize: '17px' }}>
            Check the Details
            <br />
            About Company
          </Box>
          <Box>
            <Typography variant='h3' style={{ fontFamily: 'jost', fontWeight: '700', fontSize: '35px' }}>1500+</Typography>
            <Typography style={{marginLeft: '10px'}}>users</Typography>
            
          </Box>
          <Box>
            <Typography variant='h3' style={{ fontFamily: 'jost', fontWeight: '700', fontSize: '35px' }}>500+</Typography>
            <Typography style={{marginLeft: '10px'}}>Chats</Typography>
          </Box>
          <Box>
            <Typography variant='h3' style={{ fontFamily: 'jost', fontWeight: '700', fontSize: '35px' }}>1200+</Typography>
            Video Calls
          </Box>


        </Grid>
      </Grid>

      <Grid container style={{ display: 'flex',  alignItems: 'center', background: '#360C6B', justifyContent: 'center', padding: '100px 0' }}>
        <Grid >
        

       
          <img
            style={{ borderRadius: '20px', marginRight: '40px', width: '62px',  [theme.breakpoints.down('sm')]: { width: '100%' } }}
            src={logo}
            alt="Profile"
          />
        
            
        </Grid>

        <Grid>
        <img
            style={{ borderRadius: '20px', width: '630px', marginRight: '111px', [theme.breakpoints.down('sm')]: { width: '100%' } }}
            src={vid}
            alt="Profile"
          />
        <img
            style={{ borderRadius: '20px', width: '70px', position: 'relative' , right: '466px',  [theme.breakpoints.down('sm')]: { width: '100%' } }}
            src={play}
            alt="Profile"
          />
          
</Grid>
         
      
      </Grid>

      <Footer />
  </Common.Page>


  );
};

export default About;
