import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { CreateReport } from 'src/api';
import { useDispatch, useSelector } from 'react-redux';
import ReportIcon from '@mui/icons-material/Report';
import Actions from 'src/actions';
import SettingsIcon from '@mui/icons-material/Settings';


const reports = [
  'Inappropriate Content',
  'Spam or Unwanted Messages',
  'Harassment or Bullying',
  'Impersonation',
  'Hate Speech or Discrimination',
  'Fraud or Scam',
  'Violent or Threatening Behavior',
  'Copyright Infringement',
  'Privacy Violation',
];

function ReportDialog(props) {
  const { onClose, open, HandelReport } = props;
  const [selectedValues, setSelectedValues] = useState([]);

  const handleClose = () => {
    onClose(selectedValues);
    setSelectedValues([]);
  };

  const handleListItemClick = (value) => {
    const newSelectedValues = [...selectedValues];

    if (newSelectedValues.includes(value)) {
      // Remove if already selected
      newSelectedValues.splice(newSelectedValues.indexOf(value), 1);
    } else {
      // Add if not selected
      newSelectedValues.push(value);
    }

    setSelectedValues(newSelectedValues);
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>   Report Stranger</DialogTitle>
      <List sx={{ pt: 0 }}>
        {reports.map((email) => (
          <ListItem disableGutters key={email}>
            <ListItemButton onClick={() => handleListItemClick(email)}
              selected={selectedValues.includes(email)}
            >

              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectedValues.length > 1) {
              HandelReport(selectedValues)
              setSelectedValues([])
            }

          }}

        >
          Report Stranger
        </Button>
      </div>
    </Dialog>
  );
}

ReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  HandelReport: PropTypes.func.isRequired,

};

export default function Report() {
  const [open, setOpen] = React.useState(false);
  const key = useSelector((state) => state.meeting.key);
  const name = useSelector((state) => state.user.name);
  const uuid = useSelector((state) => state.media.uuid);
  const ip = useSelector((state) => state.ip.ip);
  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpen(true);
  };
  const ChangePage =()=>{
    dispatch(Actions.Media.leaveMeeting())
     dispatch({ type: 'reset' });
  }

  const HandelReport = async (values) => {
    let data = {
      uuid,
      session: key,
      ip: ip
    }
    let report = await CreateReport(data)
    dispatch(Actions.Media.leaveMeeting())
    dispatch({ type: 'reset' })
    setOpen(false);
  }
  const handleClose = async (values) => {
    setOpen(false)
  };

  return (
    <>

    <Typography sx={{display:'flex', alignItems:'center',justifyContent:'center'}} > 
      

  
{/* <Button sx={{sm:{fontSize:'10px' }}} variant="outlined"  onClick={ ChangePage}>Skip Stranger</Button>
<Typography sx={{fontSize:'25px',padding:'0px 15px'}}> 
|
</Typography> */}
    <Button  sx={{sm:{fontSize:'10px' }}} color='error' onClick={handleClickOpen}>
        <SettingsIcon/>
        
        {/* Report Stranger */}

      </Button>
   
      <ReportDialog open={open} onClose={handleClose} HandelReport={HandelReport} />
    </Typography>

    </>
  );
}
