import { Avatar, Box, Button, Container, List, ListItemAvatar, ListItemButton, ListItemText, Typography, Checkbox, ListItem, Alert, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/actions';
import getLocalAudio from 'src/actions/media/getLocalAudio';
import getLocalVideo from 'src/actions/media/getLocalVideo';
import releaseLocalAudio from 'src/actions/media/releaseLocalAudio';
import releaseLocalVideo from 'src/actions/media/releaseLocalVideo';
import store from 'src/store';
import Utils from 'src/utils';
import OutlinedCard from 'src/views/Interest/Card';
import buttonpng from "src/assets/button.png"
import call from "src/assets/calllogo.png"
import { useTranslation } from 'react-i18next';
import { Bounce, toast, ToastContainer } from 'react-toastify';


export default function Interest() {
  const toggle = useSelector((state) => state.dropdown.show);
  const available = useSelector((state) => state.media);
  const active = useSelector((state) => !!state.media.local.video);
  const dispatch = useDispatch();

  const displayStyle = toggle ? 'flex' : 'none';
  function generateRandomName() {
    const randomString = Math.random().toString(36).substring(7);
    return `${randomString}`;
  }
  function generateRandomName() {
    const randomString = Math.random().toString(36).substring(7);
    return `${randomString}`;
  }



  const [interest, setInterest] = useState([]);
  const { t } = useTranslation();

  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const ShowInterest = useSelector((state) => state.showinterest.interest);
  const previousInterest = JSON.parse(ShowInterest);

  const handleInterestSelect = (text) => {
    // Check if the text is already selected
    if (interest.includes(text)) {
      // If already selected, remove it
      setInterest(interest.filter(item => item !== text));
    } else {
      // If not selected, add it to the selected interests
      setInterest([...interest, text]);
    }
  };

  const InterestSave = () => {
    let randomName = generateRandomName();
    if (interest.length > 0) {
      const interestJson = JSON.stringify(interest);
      dispatch({ type: 'user-email', value: `${randomName}@gmail.com` });
      dispatch({ type: 'user-name', value: `name${randomName}` });
      dispatch({ type: 'setInterest', value: interestJson });
      localStorage.setItem('interest', interestJson)
    }
    else{
     dispatch({ type: 'snack', severity: 'error', content: 'Interest is not Select' });
    }

  }

  const HandleNot = () => {
    let randomName = generateRandomName();
    let randomInterest = ["Reading"];
    const interestJson = JSON.stringify(randomInterest);
    dispatch({ type: 'user-email', value: `${randomName}@gmail.com` });
    dispatch({ type: 'user-name', value: `name${randomName}` });
    dispatch({ type: 'setInterest', value: interestJson });
    localStorage.setItem('interest', interestJson)

  }
  const interestsList = [
    { name: 'Art', backgroundColor: getRandomColor() },
    { name: 'Baking', backgroundColor: getRandomColor() },
    { name: 'Basketball', backgroundColor: getRandomColor() },
    { name: 'Blogging', backgroundColor: getRandomColor() },
    { name: 'Chess', backgroundColor: getRandomColor() },
    { name: 'Community service', backgroundColor: getRandomColor() },
    { name: 'Creative activities', backgroundColor: getRandomColor() },
    { name: 'Dance', backgroundColor: getRandomColor() },
    { name: 'Drawing', backgroundColor: getRandomColor() },
    // Uncomment and add more interests as needed
  ];

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }



  useEffect(() => {
    const fetchUserInterest = async () => {

      setInterest(previousInterest ?? []);

    };


    fetchUserInterest();
  }, [selectedLanguage]);



  return (
    <div>
        <Typography
            variant="h1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px',
              color: '#3CE261',
            }}
          >
            Meet New People with a Click!
          </Typography>
          <Typography
            sx={{
              color: 'white',
              textAlign: 'center',
              marginY: '20px',
            }}
          >
            Explore, Engage, Enjoy – Random Video Chats!
          </Typography>
      <Grid container
        // display="flex"
        // justifyContent='center'
        // alignItems='center'
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >

     
        {/* Same as */}
        {/* <ToastContainer /> */}
        {/* <Box p={1} > */}
    
          <Grid md={8} sm={12}   style={{
            background: 'linear-gradient(to right, #491A84 0%, #2B0F50 100%)',
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            borderRadius: '25px'

          }}>
            <Box  style={{ background: `url(${buttonpng}) 100% 100%`, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px', padding: '40px', backgroundRepeat: 'no-repeat', width: '165px' }}>

              <Button onClick={InterestSave} >
                <img src={call} style={{ height: "30px", padding: '0 10px' }} />
                <Typography variant='body1' style={{ color: 'white', fontWeight: 'bold' }}>
                  Start
                </Typography>
              </Button>
            </Box>
          </Grid>


          <Grid md={3} sm={12}  sx={{

            background: 'linear-gradient(to right, #491A84 0%, #2B0F50 100%)',
            padding: "20px",
            margin: '0px 40px',
            borderRadius: '25px',
            display: { xm: 'none', md: 'block' },

          }}>
            <Typography variant='h4' color="text.secondary" sx={{ color: '#ffff', fontWeight: 'bold', padding: '10px 0px' }} gutterBottom>
              {t('selectYourInterest')}
            </Typography>

            {interestsList.map((interestItem, index) => (
              <ListItem key={index} >

                <Avatar sx={{ bgcolor: interestItem.backgroundColor, color: '#fff' }}>
                  {interestItem.name.charAt(0)}
                </Avatar>
                <ListItemText sx={{ color: '#ffff', fontWeight: '500', marginLeft: '10px' }} primary={interestItem.name} />
                <Checkbox
                  checked={interest.includes(interestItem.name)}
                  onChange={() => handleInterestSelect(interestItem.name)}
                  color="primary"
                  inputProps={{ 'aria-label': interestItem.name }}
                />
              </ListItem>
            ))}
            {/* <List>
              {interestsList.map((interestItem, index) => (
                <ListItemButton
                  key={index}
                  selected={interest.includes(interestItem.name)}
                  onClick={() => handleInterestSelect(interestItem.name)}
                sx={{ backgroundColor: interestItem.backgroundColor }}
                >
                  <Avatar sx={{ bgcolor: interestItem.backgroundColor, color: '#fff' }}>
                    {interestItem.name.charAt(0)}
                  </Avatar>
                  <ListItemText sx={{ color: '#ffff', fontWeight: '500', marginLeft: '10px' }} primary={interestItem.name} />
                </ListItemButton>
              ))}
            </List> */}
          </Grid>



        </Grid>

      {/* </Box> */}
    </div>
  );
}
