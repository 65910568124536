import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import config from 'src/config';
import image from 'src/assets/logo.png'

const Page = forwardRef(({ 
  children, 
  canonicalUrl, 
  description = config.appDescription, 
  title = config.appTitle, 
  ogImage = image,
  ogType = "website",
  ...rest  
}, ref) => {

  const fullCanonicalUrl = `${config.frontendUrl}${canonicalUrl || ''}`;
  
  return (
    <div ref={ref} {...rest}>
      <Helmet>
        {/* Basic SEO tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {canonicalUrl && <link rel="canonical" href={fullCanonicalUrl} />}
        
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {canonicalUrl && <meta property="og:url" content={fullCanonicalUrl} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        <meta property="og:type" content={ogType} />
        <meta property="og:site_name" content={config.appTitle} />

      
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrl: PropTypes.string,
  ogImage: PropTypes.string, 
  ogType: PropTypes.string,  
};

export default Page;
