import { useDispatch, useSelector } from 'react-redux';
import { CallEnd, CallMissed, Videocam, VideocamOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Actions from '../../actions';
import { useEffect, useRef, useState } from 'react';
import store from '../../store';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

const VideoButton = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -25,
  left: 0,
  right: 140,
  margin: '0 auto',
  // marginTop: '-48px'
});

function CameraToggle() {
  const available = useSelector((state) => state.media.devices.video);
  const active = useSelector((state) => !!state.media.local.video);
  const video = useSelector((state) => state.permission.video);
  const Interest=useSelector((state)=>state.interest.interest)

  const dispatch = useDispatch();

  const DeleteInterest = () => {
    dispatch(Actions.Media.leaveMeeting());
    dispatch({ type: 'showInterest',value: Interest});
    dispatch({ type: 'reset' });
    localStorage.removeItem('interest')
    dispatch({ type: 'resetInterest' });
    dispatch({ type: 'show' });
  };

  return (

    <VideoButton
    id='videoButton'
    color={'primary'}

    size="medium"
    onClick={DeleteInterest}
  >
    <AddIcCallIcon style={{ width: 20 }}/>
    {/* {active ? <Videocam style={{ width: 22 }} /> : <VideocamOff style={{ width: 20 }} />} */}
  </VideoButton>
  );
}

export default CameraToggle;
