import Block from './Block';
import Home from './Home';
import Join from './Join';
import Meeting from './Meeting';
import Privacy from './Privacy';
import TermsAndCondition from './TermsandCondition';

const Views = {
  Home,
  Join,
  Meeting,
  Privacy,
  Block,
  TermsAndCondition

  
};

export default Views;
