import { Box, Button, Container, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import getTheme from 'src/theme';
import { makeStyles } from '@mui/styles';

import img from '../assets/Vector.png'
import img1 from '../assets/footer.png'
import buttonpng from "src/assets/button.png"
import call from "src/assets/calllogo.png"
import arrow from "src/assets/Arrow.png"





export default function Features() {
    const theme = getTheme();
    const useStyles = makeStyles((theme) => ({
        footer: {

            padding: '30px',
            backgroundColor: theme.palette.background.default,
            display: 'block'
        },
        text: {

            color: '#fff',
            // fontSize: '15px',
            textDecoration: 'none',
            padding: '10px 10px',
            display: 'block',
            '&:hover': {
                color: theme.palette.text.primary,
            },
        },

        title: {
            fontFamily: 'jost',
            fontWeight: 'bold',
            fontSize: '24px',
            width: '300px',
            textAlign: 'center',
            height: '100px'
        },
        link: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        image: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column !important',
            alignItems: 'center',
            padding: '40px 0',
            background: `url('${img1}')`,
            position: 'relative',
            height: '450px',

            color: theme.palette.background.paper,
            top: '220px',
            borderRadius: '60px'
        },
        icon: {
            borderRadius: ' 5px',
            lineHeight: '35px',
            height: '35px',
            width: '35px',
            display: 'block',
            background: 'white',
            color: 'black',
            margin: '0px 7px'
        },
        dropdown: {
            backgroundColor: '#0f2136',

        },
        copyrightText: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

        },
        textbox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            borderRadius: '25px !important'
        },
        textfield: {
            width: '700px',
            position: 'relative',
            borderRadius: '25px !important',
            margin: '40px 0'

        },
        btn: {
            backgroundColor: theme.palette.text.primary + ' !important',
            borderRadius: '35px !important',
            width: '150px',
            position: 'relative',
            right: '150px',
            fontSize: '20px !important',
            fontWeight: '700 !important',


        },
        
    }
    ));
    // const classes = useStyles();
    return (
        <>
            <Box style={{ background: '#2d0560'}} >
                <Container>

             

         
                <Grid container style={{paddingTop:'140px'}}>
                    <Grid  xs={12} md={4} p={2}>
                        <Typography variant='h2' sx={{ color: theme.palette.text.primary, fontWeight: '700' }}>
                        Company Video Call Features
                        </Typography>
                        <Typography variant='body' sx={{ color: theme.palette.text.secondary, fontSize: '15px' }}>
                        Here are some features listed for you to check out. You can explore how Omegle video calls work.

                        </Typography>
                    </Grid>
                    <Grid  xs={12} md={4} p={1}>
                        <Box sx={{ display: 'flex',  alignItems: 'start', marginBottom: '25px' }}>
                            <img src={img} alt='img' style={{ height: '30px' , margin:'10px' }} />

                            <Box sx={{ padding: '0 10px' }}>
                                <Typography variant='h6' style={{ fontSize: '20px', fontWeight: '700', color: 'white' }}>
                                High-definition video quality
                                </Typography>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginTop: '8px' }} >
                                Enjoy clear and sharp video resolution 
                                  during calls.
                                </Typography>

                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', marginBottom: '25px' }}>
                            <img src={img} alt='img' style={{ height: '30px', margin:'10px' }} />

                            <Box sx={{ padding: '0 10px' }}>
                                <Typography variant='h6' style={{ fontSize: '20px', fontWeight: '700', color: 'white' }}>
                                Real-time audio transmission
                                </Typography>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginTop: '8px' }} >
                                    Experience seamless and instant audio 
                                       communication.
                                </Typography>

                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', marginBottom: '25px' }}>
                            <img src={img} alt='img' style={{ height: '30px' , margin:'10px' }} />

                            <Box sx={{ padding: '0 10px' }}>
                                <Typography variant='h6' style={{ fontSize: '20px', fontWeight: '700', color: 'white'}}>
                                Screen sharing capabilities
                                </Typography>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginTop: '8px' }} >
                                Share your screen with others for 
presentations or collaborations.
                                </Typography>

                            </Box>

                        </Box>


                    </Grid>
                    <Grid  xs={12} md={4} p={1}>
                        <Box sx={{ display: 'flex', alignItems: 'start', marginBottom: '25px' }}>
                            <img src={img} alt='img' style={{ height: '30px' , margin:'10px' }} />

                            <Box sx={{ padding: '0 10px' }}>
                                <Typography variant='h6' style={{ fontSize: '20px', fontWeight: '700', color: 'white' }}>
                                Text chat during video calls
                                </Typography>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginTop: '8px' }} >
                                Communicate through text messages 
alongside video conversations.
                                </Typography>

                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start',  marginBottom: '25px' }}>
                            <img src={img} alt='img' style={{ height: '30px' , margin:'10px'}} />

                            <Box sx={{ padding: '0 10px' }}>
                                <Typography variant='h6' style={{ fontSize: '20px', fontWeight: '700', color: 'white' }}>
                                User-friendly interface
                                </Typography>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginTop: '8px' }} >
                                Navigate through calls easily with an 
intuitive and user-friendly interface.
                                </Typography>

                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', marginBottom: '25px' }}>
                            <img src={img} alt='img' style={{ height: '30px' , margin:'10px' }} />

                            <Box sx={{ padding: '0 10px' }}>
                                <Typography variant='h6' style={{ fontSize: '20px', fontWeight: '700', color: 'white' }}>
                                Privacy and security settings
                                </Typography>
                                <Typography sx={{ color: theme.palette.text.secondary, fontSize: '15px', marginTop: '8px' }} >
                                Ensure your calls are secure with privacy 
settings and encryption features.
                                </Typography>

                            </Box>

                        </Box>
                    </Grid>
                </Grid>
           
                <Grid style={{ display: 'flex' }}>

                    <Grid container xs={12} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50px',
                        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${img1})`,
                        position: 'relative',
                        height: '450px',
                        top: '100px',
                        color: theme.palette.background.paper
                    }}>
                        <Box style={{
                            width: '350px',
                            textAlign: 'center',
                            fontWeight: '700',
                            fontSize: '30px',
                            color:'white'
                        }} >
                            Discover individuals and join us for a meet-up!
                        </Box>

                        <Button style={{
                            background: `url(${buttonpng}) 100% 100% `, padding: '29px',
                            width: '152px', backgroundRepeat: 'no-repeat', marginTop: '15px'
                        }}>
                            <Typography variant='body1' style={{ color: 'white', fontWeight: 'bold' }}>
                                let Find
                            </Typography>
                            <img src={arrow} style={{
                                width: '20px',
                                marginLeft: '4px',
                            }} />
                        </Button>


                    </Grid>
                </Grid>
                </Container>
            </Box>


        </>

    );
}
