import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Common from '../common';
import config from '../config';
import MeetingBar from '../views/Meeting/MeetingBar';
import UIMatrix from '../views/Meeting/UIMatrix';
import UIPinned from '../views/Meeting/UIPinned';
import Actions from '../actions';
import LocalVideo from 'src/views/Join/LocalVideo';
import { Box, Button, Grid } from '@mui/material';
import MicrophoneToggle from 'src/views/Join/MicrophoneToggle';
import CameraToggle from 'src/views/Join/CameraToggle';
import store from 'src/store';
import releaseLocalVideo from 'src/actions/media/releaseLocalVideo';
import releaseLocalAudio from 'src/actions/media/releaseLocalAudio';
import getLocalAudio from 'src/actions/media/getLocalAudio';
import getLocalVideo from 'src/actions/media/getLocalVideo';
import MeetingDrawer from '../views/Meeting/MeetingDrawer';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flex: 1,
  },
}));
function MeetingManager() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const joined = useSelector((state) => state.media.joined);
  const ended = useSelector((state) => state.meeting.ended);
  const join = useSelector((state) => state.media.join);

  useEffect(() => {

    if (joined) {
      dispatch(Actions.Media.joinMeeting());
    }



  }, [joined]);

}
function MeetingUI() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.media.settings.ui);
  const Interest = useSelector((state) => state.interest.interest)
  const meetingDrawerOpen = useSelector((state) => state.drawer.open);


  const DeleteInterest = () => {
    dispatch(Actions.Media.leaveMeeting());
    dispatch({ type: 'showInterest', value: Interest });
    dispatch({ type: 'reset' });
    localStorage.removeItem('interest')
    dispatch({ type: 'resetInterest' });
    dispatch({ type: 'show' });
  };
  return (
    <>
      <Box style={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>

        <Grid container style={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
          <Grid md={meetingDrawerOpen ? 8 : 12} style={{ background: "#2d0560" }} >
            {ui === 'matrix' && <UIMatrix />}
            {ui === 'pinned' && <UIPinned />}
            <MeetingBar />
            {/* <Button sx={{ margin: "10px 0px" }} onClick={DeleteInterest}>
          Write your interest
        </Button> */}
          </Grid>

          <Grid md={4} style={{display:'flex',alignItems:'center',justifyContent:"center"}} >
        {  meetingDrawerOpen &&  <MeetingDrawer />}
          </Grid>
        </Grid>
      </Box>
      {/* <Box> 
        {ui === 'matrix' && <UIMatrix />}
        {ui === 'pinned' && <UIPinned />}
        <MeetingBar />
       
      </Box> */}




    </>
  );
}
function Meeting() {
  return (
    <>
      <MeetingUI />
      <MeetingManager />
    </>
  );
}
export default Meeting;
