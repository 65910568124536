import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, MenuItem } from '@mui/material';
import { GetInterestById, UpdateInterest, GetAllLaunguage } from 'src/api';
import { useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const InterestForm = () => {
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { id } = useParams();
  const navigate=useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetAllLaunguage();
        if (response.status === 200) {
          const languageData = response.data.languages;
          setLanguages(languageData);
          setSelectedLanguage(languageData.length > 0 && languageData[0].languageCode);
        }

        if (id) {
          const interestResponse = await GetInterestById(id);

          if (interestResponse.status === 200) {

            setInterests(interestResponse.data.interest.interest);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);
  const defaultTheme = createTheme();
  const handleChange = (languageCode, property) => (event) => {
    setInterests((prevInterests) =>
      prevInterests.map((interest) =>
        interest.languageCode === languageCode ? { ...interest, [property]: event.target.value } : interest
      )
    );
  };

  const handleLanguageChange = (languageCode) => {
    setSelectedLanguage(languageCode);
  };

  // const HandleUpdate=()=>{
  //   console.log('launguage', languages)
  //   console.log('interest', interests)
  // }
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = { interest: interests.filter((interest) => Object.keys(interest).length > 1) };

    if (id) {

      const response = await UpdateInterest(id, data);
      if(response.status==200){
        navigate('/super_admin/dashboard/interest')
       }      
    }
  };

  return (
    <ThemeProvider theme={defaultTheme} >
    <Container style={{margin:'20px 0'}}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            select
            label="Language"
            variant="outlined"
            fullWidth
            name="languageCode"
            value={selectedLanguage}
            onChange={(event) => handleLanguageChange(event.target.value)}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.languageCode} value={lang.languageCode}>
                {lang.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={9}>
          <form onSubmit={handleSubmit}>
            {languages.map((language) => (
              <div key={language.languageCode} style={{ display: selectedLanguage === language.languageCode && 'block' }}>
                <TextField
                  label="Value"
                  variant="outlined"
                  fullWidth
                  name={`value_${language.languageCode}`}
                  value={(interests.find((i) => i.languageCode === language.languageCode) || {}).value || ''}
                  onChange={handleChange(language.languageCode, 'value')}
                />
              </div>
            ))}

            <Button  type="submit" variant="contained" color="primary">
              {'Update Interest'}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
    </ThemeProvider>
  );
};

export default InterestForm;
