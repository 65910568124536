import React, { useState } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import XIcon from '@mui/icons-material/X';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import Actions from 'src/actions';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTranslation } from 'react-i18next';
import { BorderClear, ClassSharp, NoEncryption } from '@mui/icons-material';
import buttonpng from 'src/assets/button.png';
// import img from '../assets/Vector.png'
import img1 from '../assets/footer.png';
import call from 'src/assets/calllogo.png';

import img from '../assets/footer.png';
import arrow from '../assets/Arrow.png';
import getTheme from 'src/theme';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: '80px',
    paddingBottom: '15px',
    backgroundColor: theme.palette.background.default,
    display: 'block',
  },
  copyright: {
    padding: '18px 0px',
    backgroundColor: theme.palette.background.default,
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: '18px',
    textDecoration: 'none',
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'block',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },

  title: {
    fontFamily: 'jost',
    fontWeight: 'bold',
    fontSize: '24px',
    width: '300px',
    textAlign: 'center',
    height: '100px',
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0 0 0',
    gap: '25px',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column !important',
    alignItems: 'center',
    padding: '40px 0',
    background: `url('${img}')`,
    position: 'relative',
    height: '400px',
    bottom: '120px',
    color: theme.palette.background.paper,
  },
  icon: {
    borderRadius: ' 5px',
    lineHeight: '35px',
    height: '35px',
    width: '35px',
    display: 'block',
    background: 'white',
    color: 'black',
    margin: '0px 7px',
  },
  dropdown: {
    backgroundColor: '#0f2136',
  },
  copyrightText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textcopyright: {
    fontSize: '18px !important',
  },
  textbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    border: 'none !important',
  },
  textfield: {
    width: '700px',
    position: 'relative',
    margin: '40px 0',
    paddingLeft: '10px',
  },
  btn: {
    backgroundColor: theme.palette.text.primary + ' !important',
    borderRadius: '35px !important',
    width: '150px',
    position: 'relative',
    // right: '150px',
    fontSize: '20px !important',
    fontWeight: '700 !important',
  },
  footerheadings: {
    fontSize: '22px',
    opacity: 0.7,
    fontWeight: 400,
  },
  inputfield: {
    
    padding: '0 4px 0 15px',
    borderRadius: '30px',
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const key = useSelector((state) => state.meeting.key);
  const { t } = useTranslation();
  const theme = getTheme();

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const dispatch = useDispatch();
  const handleLanguageChange = (event) => {
    let launguage = event.target.value;
    setSelectedLanguage(event.target.value);
    dispatch({ type: 'SET_LANGUAGE', payload: launguage });
  };
  const ChangePage = () => {
    dispatch(Actions.Media.leaveMeeting());
    if (key) {
      dispatch({ type: 'reset' });
    }
  };
  const FooterIcon = [
    'About Us',
    '  Follow Us ',
    '      Privacy Policy       Terms and Conditions       Company       Copyright       Contact Us',
  ];

  return (
    <>
      <footer className={classes.footer}>
        <Container>
          {/* <Grid style={{ display: 'flex' }}>
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50px',
                background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(${img1})`,
                position: 'relative',
                height: '450px',
                bottom: '90px',
                color: theme.palette.background.paper,
              }}
            >
              <Box
                style={{
                  width: '350px',
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: '30px',
                  color: 'white',
                }}
              >
                Discover individuals and join us for a meet-up!
              </Box>

              <Button
                style={{
                  background: `url(${buttonpng}) 100% 100% `,
                  padding: '29px',
                  width: '152px',
                  backgroundRepeat: 'no-repeat',
                  marginTop: '15px',
                }}
              >
                <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold' }}>
                  let Find
                </Typography>
                <img
                  src={arrow}
                  style={{
                    width: '20px',
                    marginLeft: '4px',
                  }}
                />
              </Button>
            </Grid>
          </Grid> */}
          <Grid xs={12} md={12} className={classes.textbox}>
            <Box className={classes.inputfield}>
              <TextField
                className={classes.textfield}
                sx={{ borderRadius: '100px' }}
                label="Enter your Email for updates"
              />
              <Button className={classes.btn}>
                Submit{' '}
                <img
                  src={arrow}
                  style={{
                    width: '20px',
                    marginLeft: '4px',
                  }}
                />
              </Button>
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={12}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Link to={'/'}>
              <img src={logo} alt="Logo" style={{ height: '60px' }} />
            </Link>
          </Grid>
          <Grid xs={12} md={12} color={'white'}>
            <Grid item xs={12} sm={6} md={12} className={classes.link}>
              <Link to={'/about'} className={classes.text} variant="body2">
                {t('About Us')}
              </Link>
              <Link to={`/privacy`} className={classes.text} variant="body2">
                {t('Privacy Policy ')}
              </Link>
              <Link to={`/terms-condition`} className={classes.text} variant="body2">
                {t('Terms and Conditions ')}
              </Link>
              {/* <Link to={'/'} className={classes.text} variant="body2">
                {t('Follow Us')}
              </Link>

              <Link to={`/about`} className={classes.text} variant="body2">
                {t(' Company')}
              </Link>
              <Link to={`/about`} className={classes.text} variant="body2">
                {t('Copyright')}
              </Link>
              <Link to={`/about`} className={classes.text} variant="body2">
                {t('Contact Us')} */}
              {/* </Link> */}
            </Grid>
          </Grid>
        </Container>
      </footer>
      <Divider />
      <footer className={classes.copyright}>
        <Container maxWidth="lg">
          <Box className={classes.copyrightText}>
            <Typography color="textSecondary" className={classes.textcopyright}>
              {t('allRightsReserved', {
                year: new Date().getFullYear(),
                vidomegle: t('vidomegle'),
              })}
            </Typography>
          </Box>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
