
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Navbar from 'src/component/Navbar';
import Chat from 'src/component/Chat';
import Footer from 'src/component/Footer';
import Features from 'src/component/Features';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    // overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  split: {
    flex: '1 1 auto',
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    display: 'flex',
  },
  container: {
    height: '100%',
    zIndex: 1,
  },
}));

function MainLayout() {
  const classes = useStyles();

  return (
    <>
         <Navbar/>
    <div className={classes.root}>
      <div>
        <div >
          <div>
            <Container maxWidth="xl" >
              <div>
                <Outlet />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
    
    <Footer/>
    </>
  );
}

export default MainLayout;
