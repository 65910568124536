import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const getTheme = () => createTheme({
  palette: {
    background: {
      default: '#360C6B',
      paper: '#2d0560',
      deep: '#2d0560',
      footer: '#00002E',
    },
    primary: {
      main: '#ffff',
    },
    secondary: {
      main: '#3CE261',
    },
    message: {
      receiver: '#3C3468',
      sender: '#26084E'
    },
    text: {
      primary: '#3CE261',
      // primary:'#3CE261',
      secondary: '#D9BCFF',


    },
    mode: 'dark',
  },
  colors,

  typography,
  dark: true,
});

export default getTheme;