// Import necessary React and Material-UI components
import { useTheme } from '@mui/material/styles';
import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'src/component/Footer';
import Navbar from 'src/component/Navbar';
import Common from 'src/common';
import config from 'src/config';


const Privacy = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
   <Common.Page title={`Privacy | ${config.appTitle}`} canonicalUrl={"/privacy"}  description={"Privacy Policy of Vidomegle Information Collection Vidomegle does not require users to provide personal information for access. Limited technical data, such as IP addresses, may be collected for operational purposes."}>
      <Navbar />
      <Box style={{ minHeight: '80vh', background: theme.palette.background.default }}>
        <Container>

          <Paper sx={{ background: 'none', boxShadow: 'none ' }}>
            {/* Title */}
            <Typography variant="h1" style={{fontSize: '60px', fontWeight: '700'}}  padding="20px 0 0 0">
              {/* {t('privacyTitle')} */}
              Privacy policy
            </Typography>

            <Box style={{
              font:
                'Jost',
              fontWeight:
                "400",
              fontSize:
                '20px',
              lineHeight:
                '34.68px',
                padding:'20px 0',
                color:'white'
            }}>
              Privacy Policy of Vidomegle
              Information Collection

              Vidomegle does not require users to provide personal information for access. Limited technical data, such as IP addresses, may be collected for operational purposes.

              <Typography variant="h4"  padding="20px 0 0 0" color='#3CE261'>
              {/* {t('privacyTitle')} */}
              Usage of Information
            </Typography>
              The collected data is used solely for the functionality and improvement of Vidomegle. No personal information is stored due to the lack of login or signup features.

              
              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Third-Party Links
              </Typography>
              Vidomegle may contain links to third-party websites. This privacy policy does not apply to external sites. We are not responsible for any third-party links or websites.
              
              
              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Cookies and Tracking
              </Typography>
              Vidomegle may use cookies or similar technologies for basic functionality. These technologies do not capture personal information.

              
              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Security Measures
              </Typography>
              Vidomegle implements reasonable security measures to protect the limited data collected.

              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Data Retention
              </Typography> 
              Non-personal data may be retained for operational purposes, but no personal information is stored.

              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Children's Privacy
              </Typography> 
              Vidomegle is not intended for users under 18, and no personal information of minors is knowingly collected.

              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Policy Changes
              </Typography>  
              Vidomegle reserves the right to update this privacy policy. Users are encouraged to check for changes periodically.

              <Typography variant='h4' padding='20px 0 0 0' color='#3CE261'>
              Contact Information
              </Typography>   
              For any privacy-related concerns or inquiries, users can contact
            </Box>
            {/* <Box style={{
              font:
                'Jost',
              fontWeight:
                "400",
              fontSize:
                '20px',
              lineHeight:
                '34.68px',
                padding:'20px 0',
                color:'white'
            }}>
              Name here makes it easy to talk to strangers around the world! Browse free webcams on name here, basic video chat features are free to use. Our random video chat pairs you with a stranger for instant cam to cam chat. There are always thousands of people online and with one click you will be connected instantly to chat with strangers.
            </Box>
            <Box style={{
              font:
                'Jost',
              fontWeight:
                "400",
              fontSize:
                '20px',
              lineHeight:
                '34.68px',
                padding:'20px 0',
                color:'white'
            }}>
              Name here makes it easy to talk to strangers around the world! Browse free webcams on name here, basic video chat features are free to use. Our random video chat pairs you with a stranger for instant cam to cam chat. There are always thousands of people online and with one click you will be connected instantly to chat with strangers.
            </Box> */}
                      </Paper>
         
        </Container>

      </Box>
      <Footer />
      </Common.Page>


  );
};

// Export PrivacyPolicyPage component
export default Privacy;
